<template>
  <div class="race-input">
    <div
      role="combobox"
      :aria-expanded="(dropdownVisible && list.length) ? 'true' : 'false'"
      :aria-owns="`${id}-list`"
      aria-haspopup="listbox"
    >
      <input
        class="form-control"
        type="text"
        :id="id"
        :name="name"
        :value="value"
        :required="required"
        autocomplete="false"
        spellcheck="false"
        @keyup="onKeyUp"
        @focus="onFocus"
        @blur="onBlur"
        aria-autocomplete="list"
        :aria-controls="`${id}-list`"
        aria-activedescendant
      >
    </div>

    <div
      class="race-input__dropdown"
      v-if="dropdownVisible && list.length"
      :aria-expanded="(dropdownVisible && list.length) ? 'true' : 'false'"
      role="listbox"
      :id="`${id}-list`"
    >
      <button
        class="race-input__dropdown-item"
        type="button"
        v-for="item in list"
        :key="item"
        @mousedown="() => onRaceSelect(item.title)"
      >
        {{ item.title }}
      </button>
    </div>
  </div>
</template>

<script>
import race from '../race';

export default {
  props: {
    petType: String,
    default: String,
    name: String,
    id: String,
    required: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      value: this.default,
      dropdownVisible: false,
    };
  },

  computed: {
    list() {
      const list = race[this.petType];

      if (!this.value) {
        return [];
      }

      const results = list
        .map((item) => {
          const cloneItem = {
            title: item,
            score: 0,
          };

          this.valueWords.forEach((word) => {
            const normalized = cloneItem.title.toLowerCase();

            if (normalized.indexOf(word) >= 0) {
              cloneItem.score += 1;
            }
          });

          return cloneItem;
        })
        .filter((item) => {
          return item.score > 0;
        })
        .sort((a, b) => {
          return a.score > b.score ? -1 : 1;
        })
        .slice(0, 5);

      if (results.length === 1 && results[0].title === this.value) {
        return [];
      }

      return results;
    },

    valueWords() {
      return this.value
        ? this.value.toLowerCase().split(' ')
        : '';
    },
  },

  methods: {
    onKeyUp(event) {
      this.value = event.currentTarget.value;
    },

    onRaceSelect(title) {
      this.value = title;
    },

    onFocus() {
      this.dropdownVisible = true;
    },

    onBlur() {
      this.$nextTick(() => {
        this.dropdownVisible = false;
      });
    },
  },
};
</script>

<style>
.race-input {
  position: relative;
}

.race-input__dropdown {
  position: absolute;
  top: calc(100% + .25rem);
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  z-index: 8888;
}

.race-input__dropdown-item {
  padding: .75rem 1rem;
  background-color: transparent;
  border: 0;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.race-input__dropdown-item:focus {
  outline: none;
}
</style>
