<template>
  <div class="row date-row">
    <div class="col">
      <label for="formDay" class="visually-hidden">Dag</label>
      <select
        class="select-control"
        name="formDay"
        id="formDay"
        required
        :value="formDay"
        @change="(event) => onChange(event, 'formDay')"
      >
        <option v-for="day in 31" :key="day" :value="day">
          {{ day }}
        </option>
      </select>
    </div>

    <div class="col">
      <label for="formMonth" class="visually-hidden">Maand</label>
      <select
        class="select-control"
        name="formMonth"
        id="formMonth"
        required
        :value="formMonth"
        @change="(event) => onChange(event, 'formMonth')"
      >
        <option v-for="(month, key) in months" :key="month" :value="key">
          {{ month }}
        </option>
      </select>
    </div>

    <div class="col">
      <label for="formYear" class="visually-hidden">Jaar</label>
      <select
        class="select-control"
        name="formYear"
        id="formYear"
        required
        :value="formYear"
        @change="(event) => onChange(event, 'formYear')"
      >
        <option v-for="year in years" :key="year" :value="year">
          {{ year }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
// import pad from '../helpers/pad';
import months from '../helpers/months';

const monthsWithKeys = {};
months.forEach((month, index) => { monthsWithKeys[index + 1] = month; });

export default {
  props: {
    day: {
      type: Number,
    },

    month: {
      type: Number,
    },

    year: {
      type: Number,
    },

    minYear: {
      type: Number,
      default: (new Date()).getFullYear() - 2,
    },

    maxYear: {
      type: Number,
      default: (new Date()).getFullYear(),
    },
  },

  data() {
    const currentYear = (new Date()).getFullYear();

    const defaultDay = 1;
    const defaultMonth = (new Date().getMonth()) + 1;
    const defaultYear = currentYear > this.maxYear ? this.maxYear : currentYear;

    const years = [];

    for (let i = 0; i < (currentYear - this.minYear) + 1; i += 1) {
      if ((currentYear - i) <= this.maxYear) {
        years.push(currentYear - i);
      }
    }

    return {
      formDay: this.day ? this.day : defaultDay,
      formMonth: this.month ? this.month : defaultMonth,
      formYear: this.year ? this.year : defaultYear,
      months: monthsWithKeys,
      years,
    };
  },

  methods: {
    onChange(event, key) {
      const { value } = event.currentTarget;

      this[key] = parseInt(value, 10);

      this.$emit('change', this.formYear, this.formMonth, this.formDay);
    },
  },
};
</script>

<style>
.date-row {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.date-row > * {
  padding-left: .375rem;
  padding-right: .375rem;
}

.date-row > *:first-child,
.date-row > *:last-child {
  width: 28%;
  max-width: 28%;
}
</style>
