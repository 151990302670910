<template>
  <div>
    <head-bar title="Profiel wijzigen">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="profile">
      <div class="p-3" v-if="user">
        <image-upload
          class="mr-auto ml-auto"
          :thumbnail="user.active_pet.avatar"
          :pet-id="user.active_pet.id"
          :is-avatar="true"
          :has-overlay="false"
          @uploaded="onImageUploaded"
        />
      </div>

      <form
        class="p-2 p-md-3"
        action=""
        method="POST"
        @submit.prevent="onSubmit"
        v-if="user"
        novalidate
      >
        <Errors :errors="serverErrors" />

        <div class="form-group">
          <label for="name">Naam</label>
          <input
            class="form-control"
            type="text"
            id="name"
            name="name"
            :value="user.active_pet.name"
            required
          >
          <div class="form-error" v-if="errors.name">
            {{ errors.name }}
          </div>
        </div>

        <div class="form-group">
          <label for="formDay">Geboortedatum</label>
          <birthdate
            :min-year="minBirthYear"
            :max-year="maxBirthYear"
            :year="petBirthdateParts ? petBirthdateParts[0] : null"
            :month="petBirthdateParts ? petBirthdateParts[1] : null"
            :day="petBirthdateParts ? petBirthdateParts[2] : null"
          />
          <div class="form-error" v-if="errors.birthdate">
            {{ errors.birthdate }}
          </div>
        </div>

        <div class="form-group">
          <label for="race">Ras</label>
          <race-input
            name="race"
            id="race"
            :pet-type="user.active_pet.pet_type_identifier"
            :default="user.active_pet.race"
          />
          <div class="form-error" v-if="errors.race">
            {{ errors.race }}
          </div>
        </div>

        <div class="form-group">
          <label for="gender">Geslacht</label>
          <select
            class="select-control"
            name="gender"
            id="gender"
            :value="user.active_pet.gender"
          >
            <option value="male">
              {{ user.active_pet.pet_type_identifier === 'cat' ? 'Kater' : 'Reu' }}
            </option>
            <option value="female">
              {{ user.active_pet.pet_type_identifier === 'cat' ? 'Poes' : 'Teefje' }}
            </option>
          </select>

          <div class="form-error" v-if="errors.environment">
            {{ errors.environment }}
          </div>
        </div>

        <div class="form-group" v-if="user.active_pet.pet_type_identifier === 'cat'">
          <label for="environment">Omgeving</label>
          <select
            class="select-control"
            name="environment"
            id="environment"
            :value="user.active_pet.environment"
          >
            <option value="indoors">Binnen</option>
            <option value="outdoors">Binnen en buiten</option>
          </select>

          <div class="form-error" v-if="errors.environment">
            {{ errors.environment }}
          </div>
        </div>

        <div class="form-group">
          <label for="food_brand">Merk voer</label>
          <input
            class="form-control"
            type="text"
            id="food_brand"
            name="food_brand"
            :value="user.active_pet.food_brand"
          >
          <div class="form-error" v-if="errors.food_brand">
            {{ errors.food_brand }}
          </div>
        </div>

        <div class="form-group">
          <label for="food_category">Categorie voer</label>
          <select
            class="select-control"
            name="food_category"
            id="food_category"
            :value="user.active_pet.food_category"
          >
            <option value="dry">Droogvoer</option>
            <option value="wet">Natvoer</option>
            <option value="fresh_meat">Vers vlees</option>
            <option value="snacks">Snacks</option>
          </select>

          <div class="form-error" v-if="errors.food_category">
            {{ errors.food_category }}
          </div>
        </div>

        <Button
          button-type="submit"
          :show-arrow="false"
          classes="btn--full-width"
          :disabled="profileLoadingState === 'loading'"
        >
          Wijzigingen opslaan
        </Button>
      </form>

      <div class="p-2 p-md-3 text-center">
        <ButtonLink
          component-type="router-link"
          :to="{ name: 'PetDeleteProfile' }"
          type="primary"
        >
          Verwijder dit profiel
        </ButtonLink>
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import { parseISO, formatISO } from 'date-fns';
import AppLayout from '../AppLayout.vue';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';
import ImageUpload from '../../components/ImageUpload.vue';
import Birthdate from '../../components/Birthdate.vue';
import Button from '../../components/Button.vue';
import ButtonLink from '../../components/ButtonLink.vue';
import Errors from '../../components/Errors.vue';
import pad from '../../helpers/pad';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';
import RaceInput from '../../components/RaceInput.vue';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    RaceInput,
    Errors,
    Button,
    Birthdate,
    ImageUpload,
    HeadBarLink,
    HeadBar,
    AppLayout,
    ButtonLink,
  },

  data() {
    return {
      minBirthYear: (new Date()).getFullYear() - 3,
      maxBirthYear: (new Date()).getFullYear(),
      errors: {},
      serverErrors: [],
    };
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onImageUploaded() {
      this.$store.dispatch('fetchUser');
    },

    parseBirthdate(formData) {
      const parts = [
        formData.get('formYear'),
        pad('00', formData.get('formMonth'), true),
        pad('00', formData.get('formDay'), true),
      ];

      return parseISO(parts.join('-'));
    },

    validateBirthdate(formData) {
      const date = this.parseBirthdate(formData);

      // Make sure the date is valid (in case the user enters something like 30 february)...
      const isInvalid = !(date instanceof Date) || Number.isNaN(date.getTime());

      return !isInvalid;
    },

    onSubmit(event) {
      const formData = new FormData(event.currentTarget);

      this.errors = {};

      if (!formData.get('name')) {
        this.errors.first_name = 'Dit veld is verplicht';
      }

      if (!this.validateBirthdate(formData)) {
        this.errors.birthdate = 'Dit is geen geldige datum';
      }

      if (!Object.keys(this.errors).length) {
        this.storeForm(formData);
      }
    },

    storeForm(formData) {
      this.serverErrors = [];

      formData.append('birthdate', formatISO(this.parseBirthdate(formData)));

      this.$store.dispatch('storePetProfileForm', formData)
        .then(() => {
          this.$router.push({ name: 'Pet' });
          this.toast.success('Je profiel is opgeslagen', {
            timeout: 2500,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);

          if (!err.response) {
            this.errors = ['Er ging iets mis. Probeer het later opnieuw.'];
            return;
          }

          this.serverErrors = retrieveErrorsFromResponse(err.response);
        });
    },
  },

  computed: {
    ...mapGetters([
      'user',
      'petBirthdate',
      'petBirthdateParts',
      'profileLoadingState',
    ]),

    address() {
      return this.user ? this.user.address : {};
    },
  },
};
</script>

<style>
.app-layout.profile {
  background-color: #fff;
  padding-top: 3.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  font-weight: 500;
  color: var(--purple);
  margin-bottom: .375rem;
  display: block;
}

.form-error {
  color: var(--primary);
  margin-top: .5rem;
  font-weight: 500;
}
</style>
