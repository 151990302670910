export default {
  dog: [
    'Affenpinscher',
    'Afghaanse Windhond',
    'Airedale Terriër',
    'Akita',
    'Alaskan Malamute',
    'American Staffordshire Terriër',
    'Amerikaanse Bulldog',
    'Amerikaanse Cocker Spaniel',
    'Anatolische Herdershond',
    'Appenzeller Sennenhond',
    'Argentijnse Dog',
    'Australian Cattle Dog',
    'Australian Kelpie',
    'Australian Shepherd',
    'Australische Terriër',
    'Barsoi',
    'Basenji',
    'Basset Fauve de Bretagne',
    'Basset Hound',
    'Beagle',
    'Bearded Collie',
    'Beauceron',
    'Bedlington Terriër',
    'Berner Sennenhond',
    'Bichon Frisé',
    'Bloedhond',
    'Boerboel',
    'Bordeaux Dog',
    'Border Collie',
    'Border Terriër',
    'Boston Terriër',
    'Bouvier Des Flandres',
    'Boxer',
    'Bracco Italiano',
    'Briard',
    'Bull Terriër',
    'Bullmastiff',
    'Cairn Terriër',
    'Cane Corso',
    'Catalaanse Herdershond',
    'Cavalier King Charles Spaniel',
    'Chesapeake Bay Retriever',
    'Chihuahua',
    'Chinese Naakthond',
    'Chow Chow',
    'Clumber Spaniel',
    'Curly Coated Retriever',
    'Dalmatische Hond',
    'Dandie Dinmont Terriër',
    'Dobermann pinscher',
    'Dogo Canario',
    'Drentsche patrijshond',
    'Duitse Dog',
    'Duitse Herdershond',
    'Duitse Pinscher',
    'Duitse staande hond draadhaar en korthaar',
    'Duitse staande hond langhaar',
    'Dwergpinscher',
    'Dwergschnauzer',
    'Engelse Bulldog',
    'Engelse Cocker Spaniel',
    'Engelse setter',
    'Engelse Springer Spaniel',
    'Finse Lappenhond',
    'Flatcoated Retriever',
    'Foxterrier',
    'Franse Bulldog',
    'Golden Retriever',
    'Gordon Setter',
    'Grand Basset Griffon Vendéen',
    'Greyhound',
    'Griffon Korthals',
    'Groenendaeler',
    'Grote Keeshond',
    'Grote münsterlander',
    'Havanezer',
    'Heidewachtel',
    'Hollandse Herdershond',
    'Hollandse Smoushond',
    'Hovawart',
    'Ierse setter',
    'Ierse Waterspaniel',
    'Ierse Wolfshond',
    'IJslandse Hond',
    'Irish Glen Of Imaal Terriër',
    'Irish Soft Coated Wheaten Terriër',
    'Italiaans Windhondje',
    'Jack Russel Terriër',
    'Kerry Blue Terriër',
    'Kleine en Dwerg Keeshond',
    'Kleine poedel',
    'Komondor',
    'Koningspoedel',
    'Kooikerhondje',
    'Korthaar teckel',
    'Kuvasz',
    'Labrador Retriever',
    'Laekense herder',
    'Lancashire Heeler',
    'Landseer',
    'Langhaar teckel',
    'Leonberger',
    'Lhasa Apso',
    'Maltezer',
    'Manchester terriër',
    'Markiesje',
    'Mastiff',
    'Mastino Napoletano',
    'Mechelse herder',
    'Mexicaanse Naakthond',
    'Middenslag Keeshond',
    'Middenslag poedel',
    'Mopshond',
    'Newfoundlander',
    'Noorse Elandhond',
    'Noorse Lundehond',
    'Norwich Terriër',
    'Nova Scotia Duck Tolling Retriever',
    'Old English Sheepdog (bobtail)',
    'Otterhound',
    'Pekingees',
    'Petit Basset Griffon Vendéen',
    'Pharaohond',
    'Pointer',
    'Portugese Podenqo',
    'Portugese Waterhond',
    'Pyreneese berghond',
    'Rhodesian Ridgeback',
    'Riesenschnauzer',
    'Rottweiler',
    'Ruwhaar teckel',
    'Saarlooswolfhond',
    'Saluki',
    'Samojeed',
    'Schapendoes',
    'Schipperke',
    'Schnauzer',
    'Schotse Herdershond (collie)',
    'Schotse terriër',
    'Sharpei',
    'Shetland Sheepdog (Sheltie)',
    'Shiba (Inu)',
    'Shih Tzu',
    'Siberische Husky',
    'Sint Bernard',
    'Skye Terriër',
    'Spinone Italiano',
    'Stabyhoun',
    'Staffordshire Bullterriër',
    'Tatrahond',
    'Teckel',
    'Tevuerense herder',
    'Tibetaanse mastiff',
    'Tibetaanse Terriër',
    'Tosa (Inu)',
    'Toy poedel',
    'Vizsla',
    'Vlinderhondje',
    'Weimaraner',
    'Welsh Corgi',
    'Welsh Springer Spaniel',
    'West Highland White Terriër',
    'Wetterhoun',
    'Whippet',
    'Witte Herdershond',
    'Yorkshire Terriër',
    'Zwart Russische Terriër',
    'Zweedse Vallhund (Västgötaspets)',
    'Zwitserse Laufhund',
  ],

  cat: [
    'Abessijn',
    'American Curl',
    'Amerikaans draadhaar (American wirehair)',
    'Amerikaans korthaar (American shorthair)',
    'Amerikaanse stompstaartkat (American bobtail)',
    'Asian',
    'Balinees (langharige siamees)',
    'Bengaal',
    'Blauwe Rus',
    'Bombay',
    'Brits korthaar',
    'Brits langhaar',
    'Burmees',
    'Cornish rex',
    'Chartreux (karthuizer)',
    'Devon Rex',
    'Don sphynx',
    'Egyptische mau',
    'Europees korthaar',
    'Exotic',
    'German rex',
    'Havana Brown',
    'Heilige Birmaan',
    'Japanse stompstaartkat (Japanse bobtail)',
    'Korat',
    'Kurillen stompstaartkat',
    'LaPerm',
    'Maine Coon',
    'Manx',
    'Munchkin',
    'Nebelung (langharige blauwe Rus)',
    'Noorse boskat',
    'Ocicat',
    'Oosters korthaar',
    'Oosters langhaar',
    'Oregon rex',
    'Pers',
    'Peterbald',
    'Pixie-Bob',
    'Ragdoll',
    'Savannah',
    'Schotse vouwoorkat (Scottish fold)',
    'Selkirk rex',
    'Siamees',
    'Siberische kat',
    'Singapura',
    'Snowshoe',
    'Sokoke',
    'Somali langharige abessijn)',
    'Sphynx',
    'Thai (traditionele siamees)',
    'Tibetaan (halflangharige tonkanees)',
    'Tonkanees',
    'Toyger',
    'Turkse angora',
    'Turkse van',
    'Ural Rex',
  ],
};
