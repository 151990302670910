export default (pad, str, padLeft) => {
  if (typeof str === 'undefined') {
    return pad;
  }

  if (padLeft) {
    return (pad + str).slice(-pad.length);
  }

  return (str + pad).substring(0, pad.length);
};
